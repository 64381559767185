import React from "react";
import { GetUserLessonStatus, UpdateUserLessonStatus } from "../DatabaseFunctions";

type Props = {
    userLessonStatus: any;
    setUserLessonStatus: any;
    lessonData: any;
    userId: string;
    categoryId: string;
    courseId: string;
    lessonId: string;
}


const LessonStatusCard = (props: Props) => {

    const getLessonDataIds = (lessonData: any) => {
        let ids = [];
        for (const lesson of lessonData){
            ids.push(lesson.id);
        } 
        return ids
    }

    const updateStatus = (color: string) => {
        let activeLessonIds = getLessonDataIds(props.lessonData);
        const userLessonStatusFinal: { [key: string]: any } = {"totalLessons": props.lessonData.length, "inProgress": 0, "completed": 0};
        
        if (color !== "red"){
            userLessonStatusFinal[props.lessonId] = color;
        }

        for (const lessonId of Object.keys(props.userLessonStatus)){
            if (activeLessonIds.includes(lessonId)){
                if (lessonId !== props.lessonId){
                    userLessonStatusFinal[lessonId] = props.userLessonStatus[lessonId];
                }
            }
        }

        for (const lessonId of Object.keys(userLessonStatusFinal)){
            if (userLessonStatusFinal[lessonId] === "green"){
                userLessonStatusFinal["completed"] += 1;
            }
            else if (userLessonStatusFinal[lessonId] === "yellow"){
                userLessonStatusFinal["inProgress"] += 1;
            }
        }

        if (JSON.stringify(props.userLessonStatus) !== JSON.stringify(userLessonStatusFinal)){
            UpdateUserLessonStatus(userLessonStatusFinal, props.userId, props.categoryId, props.courseId).then((result) => {
            })
            props.setUserLessonStatus(userLessonStatusFinal)
        }
    }

    const getStatusStyle = (color: string) => {
        if (props.userLessonStatus[props.lessonId] === undefined && color === "red"){
            return "-selected";
        }
        if (props.userLessonStatus[props.lessonId] === color) {
            return "-selected";
        }
        else {
            return "";
        }
    }

    if (props.lessonId !== ""){
        return(
            <div className="lesson-card">
                <div className="card-title">
                    <p>Mark lesson status</p>
                </div>
                <div className="status-buttons">
                        <div className={"status-button bred" + getStatusStyle("red")} onClick={() => updateStatus("red")}>
                            <p>Not Started</p>
                        </div>
                        <div className={"status-button byellow" + getStatusStyle("yellow")} onClick={() => updateStatus("yellow")}>
                            <p>Needs Work</p>
                        </div>
                        <div className={"status-button bgreen" + getStatusStyle("green")} onClick={() => updateStatus("green")}>
                            <p>Complete</p>
                        </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div></div>
        )
    }
}

export default LessonStatusCard;