import React from 'react';

type Props = {
  progressBarData: any,
};

const ProgressBarLesson = ({ progressBarData }: Props) => {

    const notStarted = progressBarData.total -  progressBarData.completed -  progressBarData.inProgress;

  const containerStyles = {
    height: '4px',
    width: '100%',
    backgroundColor: '#6b0000',
    display: 'flex',
    overflow: 'hidden',
  };

  const barStyles = {
    height: '100%',
    transition: 'background-color 0.2s ease-in',
  };

  const greenBarStyles = {
    ...barStyles,
    backgroundColor: 'rgb(0, 170, 0)',
  };

  const yellowBarStyles = {
    ...barStyles,
    backgroundColor: 'rgb(235, 130, 0)',
  };

  const redBarStyles = {
    ...barStyles,
    // backgroundColor: '#31256b',
    // backgroundColor: '#6b0000',
    backgroundColor: 'rgb(201, 45, 45)',
  };

  const separatorStyles = {
    width: '1px',
    backgroundColor: 'black',
    marginLeft: '-1px',
  };

  const renderBars = () => {
    const total =  progressBarData.completed +  progressBarData.inProgress + notStarted;
    const bars = [];

    for (let i = 0; i < total; i++) {
      let barWidth = '0%';
      let barStyle = redBarStyles;

      if (i <  progressBarData.completed) {
        barWidth = `${100 / total}%`;
        barStyle = greenBarStyles;
      } else if (i <  progressBarData.completed +  progressBarData.inProgress) {
        barWidth = `${100 / total}%`;
        barStyle = yellowBarStyles;
      } else if (i <  progressBarData.completed +  progressBarData.inProgress + notStarted) {
        barWidth = `${100 / total}%`;
        barStyle = redBarStyles;
      }

      bars.push(
        <React.Fragment key={i}>
          <div style={{ ...barStyle, width: barWidth }} />
          {/* {i !== total - 1 && <div style={separatorStyles} />} */}
        </React.Fragment>
      );
    }

    return bars;
  };

  return (
    <>
      <div style={containerStyles}>{renderBars()}</div>
      <p style={{color: "white", fontWeight: "lighter"}}>{Math.round( progressBarData.completed*100/ progressBarData.total)}% Completed</p>
    </>
  )
  
};

export default ProgressBarLesson;