import React, { useState } from 'react';
import {BrowserRouter,Routes, Route} from "react-router-dom";
import './App.css';
import CoursesPage from './pages/Courses';
import CourseChapters from './pages/CourseChapters';
import Topbar from './components/Topbar';
import DefaultSidebar from './components/DefaultSidebar';
import LessonView from './pages/LessonView';
import HomePage from './pages/HomePage';
import Authenticate from './components/Authenticate';

function Pages() {

  const [activeUserId, setActiveUserId] = useState("unset");
  const [sidebarOpen, setSidebarOpen] = React.useState(true);
  const [showSidebarToggle, setShowSidebarToggle] = React.useState(true);


  const [showDefaultSidebar, setShowDefaultSidebar] = React.useState(true);
  const [overlappingSidebar, setOverlappingSidebar] = React.useState(false);


  return (
    <>

    <Topbar setActiveUserId={setActiveUserId} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} showSidebarToggle={showSidebarToggle} />

    {showDefaultSidebar && <DefaultSidebar overlappingBar={overlappingSidebar} showSidebar={sidebarOpen}/>}


        <Routes>
            <Route path="/" element={<HomePage activeUserId={activeUserId} sidebarOpen={sidebarOpen} setOverlappingSidebar={setOverlappingSidebar} setShowDefaultSidebar={setShowDefaultSidebar}/>} />
            <Route path="/all" element={<CoursesPage activeUserId={activeUserId}/>} />
            <Route path="/id=:courseId/vd=:versionDate" element={<CourseChapters activeUserId={activeUserId} setOverlappingSidebar={setOverlappingSidebar} setShowDefaultSidebar={setShowDefaultSidebar} setSidebarOpen={setSidebarOpen}/>} />
            <Route path="/id=:courseId/vd=:versionDate/admin=:adminAccess" element={<CourseChapters activeUserId={activeUserId} setOverlappingSidebar={setOverlappingSidebar} setShowDefaultSidebar={setShowDefaultSidebar} setSidebarOpen={setSidebarOpen}/>} />
            <Route path="/id=:courseId/lessons/category=:categoryId/hasLessons=:hasLessons" element={<LessonView activeUserId={activeUserId} setShowSidebarToggle={setShowSidebarToggle} setOverlappingSidebar={setOverlappingSidebar} isSidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>} />
            <Route path="/id=:courseId/lessons/category=:categoryId/admin=:adminAccess" element={<LessonView activeUserId={activeUserId} setShowSidebarToggle={setShowSidebarToggle} setOverlappingSidebar={setOverlappingSidebar} isSidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>} />
            <Route path="/id=:courseId/lessons/category=:categoryId/hasLessons=:hasLessons/admin=:adminAccess" element={<LessonView activeUserId={activeUserId} setShowSidebarToggle={setShowSidebarToggle} setOverlappingSidebar={setOverlappingSidebar} isSidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>} />
          {/* <Route path="/courses/buyid=:courseID" element={} /> */}
        </Routes>
 


    </>
  );
}

export default Pages;
