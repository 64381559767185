import { HiGlobeAlt } from "react-icons/hi"
import SidebarButton from "./SidebarButton"
import "../styles/DefaultSidebar.css"
import "../mobile_styles/DefaultSidebar.css"

type Props = {
    showSidebar: boolean;
    overlappingBar: boolean;
}

const DefaultSidebar = (props: Props) => {

    return (
        <div className={props.showSidebar ? "sidebar open" : "sidebar closed"}  style={props.overlappingBar ? {position: "fixed", "left": "0", top: "1px", zIndex: "1"} : {float: "left"}}>

            <div className="sidebar-buttons-container">
                <SidebarButton buttonText="Home" url="/" iconName={"HiHome"}/>
                <SidebarButton buttonText="Courses" url="/coursess" iconName={"HiBookOpen"}/>
                <SidebarButton buttonText="Analytics" url="/analytics" iconName={"HiChartBar"}/>
                <SidebarButton buttonText="Settings" url="/settings" iconName={"HiGlobe"}/>
                <SidebarButton buttonText="Support" url="/support" iconName={"HiPhone"}/>
            </div>

        </div>
    )

}

export default DefaultSidebar;