import 'firebase/compat/storage';
import { storage } from './Firebase';
import { ref, uploadBytesResumable, deleteObject } from 'firebase/storage';

export function uploadResource(path: string, fileName: string, file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const storageRef = ref(storage, path + fileName);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
        uploadTask.on(
            'state_changed',
            (snapshot) => {
            // You can track the file upload progress here if needed
            },
            (error) => {
            // Handle any errors that occur during the upload process
            console.error(error);
            reject(error);
            },
            () => {
            // Handle successful upload
            resolve("Success");
            }
        );
    });
}

export function deleteResource(path: string, fileName: string): Promise<string> {
    console.log("function call")
    return new Promise<string>((resolve, reject) => {
        console.log("inside return")
        console.log(path)
        const storageRef = ref(storage, path + fileName);
        console.log("delete process")
        deleteObject(storageRef)
        .then(() => {
            // Handle successful deletion
            resolve("Success");
        })
        .catch((error) => {
            // Handle any errors that occur during the deletion process
            console.error(error);
            console.log("error")
            reject(error);
        });
    });
  }
  
  
  
  
  
  
  