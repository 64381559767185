import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { GetCourses, GetTable } from "../DatabaseFunctions";
import ProductCardsGridRow from "../components/ProductCardsGridRow";
import { DocumentData } from 'firebase/firestore/lite';
import "../styles/CoursesPage.css"
import "../mobile_styles/CoursesPage.css"
import Topbar from "../components/Topbar";
import { CourseItemConfig } from "../enums/CourseItemConfig";
import ProductCardsGrid from "../components/ProductCardsGrid";

type Props = {
    activeUserId: string;
}

const CoursesPage = (props: Props) => {


    return (
        <div>

            <Sidebar current_key="courses"/>

            <div className="page-container">
                
            <div className="notice-bar">
                <div className="notice-bar-content">
                    <p>Use PROMO Code STUDYWELCOME for £20 off. Limited time only!</p>
                </div>
            </div>


                <div className="page-content-wrapper">
                    
                    <ProductCardsGrid activeUserId={props.activeUserId} />

                    <div className="footer">
                        <p>&copy; studyspace.io</p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default CoursesPage;