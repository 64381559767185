import { DocumentData } from "firebase/firestore/lite";
import React from "react";
import { MarkCategoryLessonFlag, updateCategoryLessons } from "../DatabaseFunctions";
import smoothscroll from 'smoothscroll-polyfill';
import { v4 as uuidv4 } from 'uuid';

type Props = {
    adminAccess: boolean,
    courseId: string,
    categoryId: string,
    lessonData: any,
    setLessonData: any,
    lessonDataClone: any,
    setLessonDataClone: any,
    addingLesson: boolean,
    setAddingLesson: any,
    setSelectedLesson: any,
    editorEnabled: boolean,
    lastLessonRef: any,
    updateLessonTable: any,
    newLessonName: string,
    setNewLessonName: any,
    lessonBarUpdating: boolean,
    setLessonBarUpdating: any,
    resetAddLessonPrompt: any,
}

const LessonBarAdminControls = (props: Props) => {


    const newLessonValueChange = (event: any) => {
        props.setNewLessonName(event.target.value);
    }

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            if (props.addingLesson){
                submitNewLesson();
            }
        }
    }


    const submitNewLesson = () => {

        if (props.newLessonName !== "") {
            props.setLessonBarUpdating(true);
            let currentLessonContentClone: DocumentData[];
            if (props.lessonData !== undefined){
                currentLessonContentClone = JSON.parse(JSON.stringify(props.lessonData));
            }
            else {
                currentLessonContentClone = [];
            }
            let newLessonId = uuidv4();
            currentLessonContentClone.push ({
                                                "id": newLessonId,
                                                "name": props.newLessonName,
                                            })
            props.updateLessonTable(currentLessonContentClone, true);
            props.setSelectedLesson(newLessonId);

        }
    }

    const reorderLesson = () => {
        props.setLessonBarUpdating(true);
        props.updateLessonTable(props.lessonDataClone);
    }

    
    const addingLessonPrompt = () => {
        if (props.addingLesson) {
            return(
                <div className="add-lesson-prompt lb-start">
                    <p>{props.lessonData.length + 1}: </p> &nbsp;
                    <input className="search-bar" name="new-lesson" placeholder="New Lesson Name" onChange={newLessonValueChange} onKeyDown={handleKeyDown} autoComplete="off" autoFocus/>
                </div>
            )   
        }
    }


    let adminButton = () => {
        if (props.adminAccess && !props.lessonBarUpdating && !props.editorEnabled) {

            if (props.addingLesson) {
                return (
                    <div className="admin-button-container">
                        <button className={"admin-button button-primary"} onClick={props.resetAddLessonPrompt}>
                            <p>Cancel</p>
                        </button> 
                        
                        {(props.newLessonName !== "") && 
                                <>
                                    &nbsp;
                                    <button className={"admin-button bg-green"} onClick={submitNewLesson}>
                                        <p>Confirm</p>
                                    </button>
                                </>
                        }
                        
                    </div>
                )
            }
            else if (JSON.stringify(props.lessonData) === JSON.stringify(props.lessonDataClone)){
                if (!props.lessonBarUpdating){
                    return (
                        <button className={"admin-button button-primary"} 
                                onClick={() => {
                                                    smoothscroll.polyfill();
                                                    props.setAddingLesson(true);

                                                    setTimeout(() => {
                                                        try{
                                                            props.lastLessonRef.current.scrollIntoView({behavior: "smooth"});
                                                        }
                                                        catch (error) {
                                                            console.log("no lessons to scroll to yet")
                                                        }
                                                    }, 1);
                                                }}>

                            <p>Add Lesson</p>
                        </button>
                    )
                }
            }
            else {
                return (
                    <>
                    <button className={"admin-button bg-red"} onClick={() => props.setLessonDataClone(props.lessonData)}>
                        <p>Cancel</p>
                    </button>
                    &nbsp;
                    <button className={"admin-button bg-green"} onClick={reorderLesson}>
                        <p>Reorder</p>
                    </button>
                    </>
            )
            }
        }
    }


    return (

        <>
            {addingLessonPrompt()}

            {adminButton()}
        </>
    )
}


export default LessonBarAdminControls;