import React, { useState } from "react"
import "../styles/Sidebar.css"
import "../mobile_styles/Sidebar.css"
import logo from "../img/logo1.png"
import app from '../Firebase'
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import SignIn from "./SignIn";
import Navbar from "./Navbar";

type Props = {
    current_key: string;
}


const db = getFirestore(app);

const Sidebar = (props: Props) => {


    return (
        <div className="sidebar-container">


            <div className="navbar-outer">
                <Navbar current_key={props.current_key} />
            </div>


        </div>
    )
}

export default Sidebar;