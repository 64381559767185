import { applyActionCode, getAuth } from "firebase/auth";
import React from "react";

const AuthAction = () => {

    const auth = getAuth();

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);


    const mode = params.get("mode");
    const oobCode = params.get("oobCode");
    const apiKey = params.get("apiKey");
    const continueUrl = params.get("continueUrl");
    const [status, setStatus] = React.useState("");

    React.useEffect(() => {

        if (oobCode && mode=="verifyEmail") {
            applyActionCode(auth, oobCode).then((result) => {
                setStatus("verifySuccess")
            }).catch((error) => {
                console.log("error in apply action: ", error)
                setStatus("An error has occurred")
            })
        }

    }, [])


    if (status === "verifySuccess"){
        return (
            <div style={{color: "white"}}>
                <p>Your email address has been verified</p>
                {continueUrl && <button onClick={()=>window.location.href=continueUrl}>Continue</button>}
            </div>
        )
    }
    else {
        return (
            <></>
        )
    }
}

export default AuthAction;