import { DocumentData } from "firebase/firestore/lite";
import React from "react";
import { MarkCategoryLessonFlag, updateCategoryLessons } from "../DatabaseFunctions";
import "../styles/LessonView.css";
import LessonBarAdminControls from "./LessonBarAdminControls";
import LessonButtonList from "./LessonButtonList";
import ProgressBarLesson from "./ProgressBarLesson";

type Props = {
    courseId: string;
    categoryId: string;
    lessonData: DocumentData[];
    setLessonData: any;
    selectedLesson: string;
    setSelectedLesson: any;
    adminAccess: boolean;
    userLessonStatus: any;
    editorEnabled: boolean
    setEditorEnabled: any;
    goToLessonViewMobile: any;
}

const LessonBar = (props: Props) => {

    const [lessonDataClone, setLessonDataClone] = React.useState();
    const [searchValue, setSearchValue] = React.useState("");
    const [addingLesson, setAddingLesson] = React.useState(false);
    const lastLessonRef = React.useRef(null);
    const [newLessonName, setNewLessonName] = React.useState("");
    const [lessonBarUpdating, setLessonBarUpdating] = React.useState(true);
    const [progress, setProgress] = React.useState(0);


    React.useEffect(() => {
        if (props.lessonData !== undefined){
            setLessonDataClone(JSON.parse(JSON.stringify(props.lessonData)));
            setLessonBarUpdating(false);
        }
    }, [props.lessonData])

    React.useEffect(() => {
        if (props.userLessonStatus.completed !== undefined && props.lessonData !== undefined){
            setProgress(Math.round(props.userLessonStatus.completed * 100 / props.lessonData.length))
        }
    }, [props.userLessonStatus, props.lessonData])


    const searchChange = (event: any) => {
        setSearchValue(event.target.value.toLowerCase());
    }
    
    const searchFilter = (lesson: any) => {
        if(searchValue === "") {
            return true
        }
        else if (lesson.name.toLowerCase().includes(searchValue)) {
            return true;
        }
        return false;
    }

    const getSelectedLessonStyle = (lessonId: string) => {
        if (lessonId === props.selectedLesson){
            return "lesson-button-selected";
        }
        else {
            return "lesson-button-regular";
        }
    }

    const getLessonStatusStyle = (lessonId: string) => {
        if (props.userLessonStatus[lessonId] === undefined || props.userLessonStatus[lessonId] === "red"){
            return "lb-start"
        }
        else if (props.userLessonStatus[lessonId] === "yellow"){
            return "lb-review"
        }
        else if (props.userLessonStatus[lessonId] === "green"){
            return "lb-ready"
        }

    }

    const resetAddLessonPrompt = () => {
        setAddingLesson(false);
        setNewLessonName("");
    }

    const updateLessonTable = (newLessonTableData: any, enableLessonEditor=false) => {
        if (props.adminAccess){
            if (newLessonTableData.length >= props.lessonData.length - 1){
                if (props.courseId !== undefined && props.categoryId !== undefined){
                    setLessonBarUpdating(true);
                    updateCategoryLessons(props.courseId, props.categoryId, newLessonTableData).then((result) => {
                        if (result === "Success"){

                            if (props.lessonData === undefined || props.lessonData.length == 0){
                                console.log("MARKING CHAPTER HAS LESSON")
                                MarkCategoryLessonFlag(props.courseId, props.categoryId, "");
                            }
                            props.setLessonData(newLessonTableData);
                            resetAddLessonPrompt();
                            setLessonBarUpdating(false);
                            if (enableLessonEditor) {
                                props.setEditorEnabled(true);
                            }

                            if (addingLesson && lastLessonRef.current){
                                (lastLessonRef.current as unknown as HTMLElement).scrollIntoView({behavior: "smooth"});
                            }
                        }
                        // TODO: Deal with fail case and turn loading off
                    });
                }
            }
        }

    }

    const getLessonDataIds = (lessonData: any) => {
        let ids = [];
        for (const lesson of lessonData){
            ids.push(lesson.id);
        } 
        return ids
    }

    const countCompleted = () => {
        let activeLessonIds = getLessonDataIds(props.lessonData);
        let output = {completed: 0, inProgress: 0, total: [props.lessonData.length]}

        for (const lessonId of Object.keys(props.userLessonStatus)){
            if (activeLessonIds.includes(lessonId)){
                if (props.userLessonStatus[lessonId] === "green"){
                    output.completed += 1;
                }
                else if (props.userLessonStatus[lessonId] === "yellow"){
                    output.inProgress += 1;
                }
            }
        }

        return output;
    }


      return (
        <div className="lesson-bar-container">
            <div className="lesson-bar">
            <input className="search-bar" name="search" placeholder="Search" onChange={searchChange} autoComplete="off"/>
            
                <LessonButtonList
                    adminAccess = {props.adminAccess}
                    addingLesson = {addingLesson}
                    lastLessonRef = {lastLessonRef}
                    lessonData = {lessonDataClone}
                    setLessonData = {setLessonDataClone} 
                    searchFilter = {searchFilter} 
                    getLessonStatusStyle = {getLessonStatusStyle} 
                    getSelectedLessonStyle = {getSelectedLessonStyle} 
                    currentLesson = {props.selectedLesson}
                    setSelectedLesson = {props.setSelectedLesson} 
                    updateLessonTable = {updateLessonTable}
                    editorEnabled = {props.editorEnabled}
                    setEditorEnabled={props.setEditorEnabled}
                    goToLessonViewMobile={props.goToLessonViewMobile}
                />

            <LessonBarAdminControls

                adminAccess = {props.adminAccess}
                courseId = {props.courseId}
                categoryId = {props.categoryId}
                lessonData = {props.lessonData}
                setLessonData = {props.setLessonData}
                lessonDataClone = {lessonDataClone}
                setLessonDataClone = {setLessonDataClone}
                addingLesson = {addingLesson}
                setAddingLesson = {setAddingLesson}
                setSelectedLesson = {props.setSelectedLesson}
                editorEnabled = {props.editorEnabled}
                lastLessonRef = {lastLessonRef}
                updateLessonTable = {updateLessonTable}
                newLessonName = {newLessonName}
                setNewLessonName = {setNewLessonName}
                lessonBarUpdating = {lessonBarUpdating}
                setLessonBarUpdating = {setLessonBarUpdating}
                resetAddLessonPrompt = {resetAddLessonPrompt}
            />


            </div>


            <div className="lesson-progress-bar">
                { (props.lessonData !== undefined && props.lessonData.length > 0) &&
                    <ProgressBarLesson progressBarData={countCompleted()}/>
                }
            </div>

        </div>
      );
}

export default LessonBar;

