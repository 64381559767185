import Modal from "react-modal";
import React from 'react';

type Props = {
    deletePopupStatus: any;
    setDeletePopupStatus: any;
    onConfirm: any;
}


const PopupAlert = (props: Props) => {

    return (
        <div>
            <Modal
                isOpen={props.deletePopupStatus.show}
                onRequestClose={() => props.setDeletePopupStatus({show: false, id: "nullString"})}
                style={{
                overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                },
                content: {
                    top: "30%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "180px",
                    width: "500px",
                    border: "2px solid #353535",
                    backgroundColor: "black",
                    color: "white",
                    overflow: "hidden",
                    zIndex: 99999999,
                },
                }}
            >
                <h2>Are you sure you want to delete?</h2>
                <p>Once deleted, this lesson is gone forever.</p>
                <button className={"admin-content-button bg-red"} onClick={() => props.setDeletePopupStatus({show: false, id: "nullString"})}><p>Cancel</p></button>
                &nbsp; <button className={"admin-content-button bg-green"} onClick={() => props.onConfirm(props.deletePopupStatus.id)}><p>Confirm</p></button>
            </Modal>
        </div>
    )


}

export default PopupAlert;