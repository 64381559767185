import "../styles/NavbarButton.css"
import "../mobile_styles/NavbarButton.css"
import { url } from "node:inspector"

type Props = {
    text: string;
    url: string;
    button_key: string;
    current_key: string;
}

const NavbarButton = (props: Props) => {

    const onButtonClick = () => {
        window.location.href = props.url;
    }

    if (props.current_key === props.button_key){
        return (
            <div className="navbar-button navbar-button-active" onClick={onButtonClick}>
                <p>{props.text}</p>
            </div>
        )
    }
    else if (props.button_key === "gcsemaths" ){
        return (
            <div className="navbar-button promotional" onClick={onButtonClick}>
                <p>{props.text}</p>
            </div>
        )
    }
    else{
        return (
            <div className="navbar-button" onClick={onButtonClick}>
                <p>{props.text}</p>
            </div>
        )
    }
}

export default NavbarButton;