import React from "react";
import { useRef } from "react";
import { useDrag } from "react-dnd";
import LessonButtonContextMenu from "./LessonButtonContextMenu";
import LessonButtonList from "./LessonButtonList";


type Props = {

    lessonStatusStyle: string;
    selectedLessonStyle: string;
    setSelectedLesson: any;
    setEditorEnabled: any;
    lesson: any;
    index: number;
    lastLessonRef: any;
    handleRightClick: any;
    isRightClicked: boolean;
    goToLessonViewMobile: any;
}



const DraggableLessonButton = (props: Props) => {



    return (
        <div id={props.lesson.id} className={props.lessonStatusStyle + " lesson-button " + props.selectedLessonStyle } onContextMenu={props.handleRightClick}
            key={props.lesson.id + "-key"}
            style={{ ...(props.isRightClicked && { backgroundColor: "rgb(28, 28, 32)" }) }}
            {...(props.lastLessonRef != 0 && {ref: props.lastLessonRef}) }
            onClick={() => {
                props.setSelectedLesson(props.lesson.id);
                props.setEditorEnabled(false);
                props.goToLessonViewMobile();
            }
        }
        >
            
            <p id={props.lesson.id}>{props.index + 1 + ': ' + props.lesson.name}</p>
        
        </div>

    )
}

export default DraggableLessonButton;