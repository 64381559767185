import { DocumentData } from "firebase/firestore/lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LessonBar from "../components/LessonBar";
import LessonContent from "../components/LessonContent";
import { GetCategoryLessons, GetUserLessonStatus, updateSingleLesson } from "../DatabaseFunctions";

import "../styles/LessonView.css"
import "../mobile_styles/LessonView.css"

import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import LessonMobileBar from "../components/LessonMobileBar";

type Props = {
    activeUserId: string;
    setShowSidebarToggle: any;
    setOverlappingSidebar: any;
    setSidebarOpen: any;
    isSidebarOpen: boolean;
}


const LessonView = (props : Props) => {

    const [adminAccess, setAdminAccess] = React.useState(false);
    const [lessonData, setLessonData] = React.useState<DocumentData[]>();
    const [selectedLesson, setSelectedLesson] = React.useState("");
    const [userLessonStatus, setUserLessonStatus] = React.useState({});
    const [editorEnabled, setEditorEnabled] = React.useState(false);
    const { courseId } = useParams();
    const { categoryId } = useParams();
    const { hasLessons } = useParams();

    const [showLessonBar, setShowLessonBar] = React.useState(true);
    const [showToolBar, setShowToolBar] = React.useState(true);
    const [showMainContent, setShowMainContent] = React.useState(true);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobileView, setMobileView] = React.useState(false);

    React.useEffect(() => {
        props.setShowSidebarToggle(true);
        props.setSidebarOpen(false);
        props.setOverlappingSidebar(true);

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
          };
      
          window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [])

    React.useEffect(() => {
        if (windowWidth < 1000 && !mobileView){
            setMobileView(true);
            setShowToolBar(false);
            setShowMainContent(false);
        }
        else if (windowWidth >= 1000 && mobileView){
            setMobileView(false);
            setShowToolBar(true);
            setShowMainContent(true);
            setShowLessonBar(true);
        }
    }, [windowWidth])

    const goToLessonViewMobile = () => {
        if (mobileView){
            setShowToolBar(false);
            setShowLessonBar(false);
            setShowMainContent(true);
        }
    }


    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    React.useEffect(() => {
        if (hasLessons === "True" && props.activeUserId !== "unset" && courseId && JSON.parse(localStorage.getItem("userCourses-" + props.activeUserId)!)[courseId!] !== undefined && JSON.parse(localStorage.getItem("userCourses-" + props.activeUserId)!)[courseId!]["access"] == true){
            if (JSON.parse(localStorage.getItem("userCourses-" + props.activeUserId)!)[courseId!]["admin"]){
                setAdminAccess(true);
            }
            GetCategoryLessons(categoryId!).then((result) => {
                if (result.length > 0){
                    setLessonData(result);
                    setSelectedLesson(result[0].id);
                }
                else{
                    setLessonData([])
                }
            })
            GetUserLessonStatus(props.activeUserId, categoryId!, courseId).then((result) => {
                setUserLessonStatus(result);
            })
        }
        else if (props.activeUserId !== "unset" && courseId && JSON.parse(localStorage.getItem("userCourses-" + props.activeUserId)!)[courseId!] !== undefined && JSON.parse(localStorage.getItem("userCourses-" + props.activeUserId)!)[courseId!]["access"] == true){
            if (JSON.parse(localStorage.getItem("userCourses-" + props.activeUserId)!)[courseId!]["admin"]){
                setAdminAccess(true);
            }
        }
        else {
            setLessonData([]);
        }
    }, [props.activeUserId])

    const updateLesson = (lessonId: string, newLessonObject: any) => {
        if (lessonData !== undefined && courseId !== undefined && categoryId !== undefined){
            updateSingleLesson(lessonData, lessonId, courseId, categoryId, newLessonObject).then(
                (result) => {
                    if (result !== "Failed") {
                        setLessonData(result);
                    }
                }
            )
        }
    }


    const getCurrentLessonContent = () => {
        if (lessonData !== undefined){
            return lessonData.filter((lesson) => {return lesson.id === selectedLesson})[0];
        }
        else{
            return {}
        }

    }

        return (
            <div>
                <div className="lesson-page-container"> 
                    { mobileView && <LessonMobileBar showLessonBar={showLessonBar} showToolBar={showToolBar} showMainContent={showMainContent} setShowLessonBar={setShowLessonBar} setShowToolBar={setShowToolBar} setShowMainContent={setShowMainContent}/> }
                    {showLessonBar && <LessonBar adminAccess={adminAccess!} courseId={courseId!} categoryId={categoryId!} lessonData={lessonData!} setLessonData={setLessonData} selectedLesson={selectedLesson} setSelectedLesson={setSelectedLesson} userLessonStatus={userLessonStatus} editorEnabled={editorEnabled} setEditorEnabled={setEditorEnabled} goToLessonViewMobile={goToLessonViewMobile}/>}
                    <LessonContent adminAccess={adminAccess!} activeUserId={props.activeUserId} allLessonData={lessonData!} currentLesson={getCurrentLessonContent()} courseId={courseId!} categoryId={categoryId!} userLessonStatus={userLessonStatus} setUserLessonStatus={setUserLessonStatus} updateLesson={updateLesson} editorEnabled={editorEnabled} setEditorEnabled={setEditorEnabled} showToolBar={showToolBar} showMainContent={showMainContent} />
                </div>
            </div>
        )
}

export default LessonView;