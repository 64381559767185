import "../styles/ProductCard.css"
import "../mobile_styles/ProductCard.css"
import ProgressBar from "./ProgressBar";
import { url } from "inspector";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { SignInWithEmailPopup, SignInWithGoogle } from "./SignIn";
import React from "react";

type Props = {
    id: string;
    productName: string;
    description: string;
    price: number;
    userCourseData: any;
    totalComponents: number;
    thumbnailUrl: string;
    url: string;
    cardColor: string;
    titleColor: string;
    myCourses: boolean;
    activeUserId: string;
}

const ProductCard = (props: Props) => {

    const [showInfo, setShowInfo] = React.useState(true);
    const [barColor, setBarColor] = React.useState("red");
    const [productInfoClass, setProductInfoClass] = React.useState("product-info-hide-bar")
    const [percentageCompleted, setPercentageCompleted] = React.useState(0);
    const [userOwned, setUserOwned] = React.useState(false);


    React.useEffect(()=>{
        if (props.userCourseData[props.id] !== undefined){
            setPercentageCompleted(Math.round(100*props.userCourseData[props.id]["componentsCompleted"]/props.totalComponents));
            setUserOwned(true);
        }
    }, [props.userCourseData, props.activeUserId])

    React.useEffect(()=>{
        if (percentageCompleted === 100){
            setBarColor("#00ee14");
        }
        else if (percentageCompleted > 0){
            setBarColor("#ff9900fd");
        }
        else {
            setBarColor("rgb(133, 0, 0)");
        }
    }, [percentageCompleted])

    React.useEffect(()=>{
        if (props.myCourses){
            setProductInfoClass("product-info-show-bar")
        }
        else {
            setProductInfoClass("product-info-hide-bar")
        }
    }, [props.myCourses, showInfo])



    const loadItem = (url: string) => {

        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            // Check user is signed in
            if (user) {
                //We should check here if user owns the product.
                window.location.assign(url);
                //If user doesn't own then send to payment page
            }
            else {
                SignInWithEmailPopup();
                // SignInWithGoogle("/");
            }
        })

    }

    return (
        <div className="product-card-container" 
                onMouseEnter={() => setShowInfo(true)} 
                onMouseLeave={() => setShowInfo(false)} 
                onClick={() => loadItem(props.url)}
                style={{backgroundColor: barColor}}>

            <div className="product-thumbnail" style={{backgroundImage: "url(" + props.thumbnailUrl + ")"}}>
                <h2 id="cardTitle" style={{color:props.titleColor}}>{props.productName}</h2>

            </div>

            <div id="product-info" className={"product-info " + productInfoClass} style={{backgroundColor: props.cardColor}}>

            {/* {!props.myCourses && (
                <div className="product-desc-tr">
                    <div className="product-desc">
                        <p> {props.description} </p>
                    </div>
                </div>
            )} */}

            {userOwned && (props.activeUserId !== "") && (
            <div className="progress-bar-container-tr">
                <div className="progress-bar-container">
                    <p>{percentageCompleted}% Completed </p>
                </div>
            </div>
            )}

            {(!userOwned || (props.activeUserId == "")) && (
                <div className="subscription-data-tr">
                    <div className="subscription-data">
                        <p>£{props.price} </p>
                    </div>
                </div>
            )}

            </div>

        
        </div>
    )
}

export default ProductCard;