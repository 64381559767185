import NavbarButton from "./NavbarButton";

type Props = {
    current_key: string;
}

const Navbar = (props: Props) => {

    return (
        <div className="navbar-container">
            <NavbarButton text="Home" url="" button_key="home" current_key={props.current_key}/>
            <NavbarButton text="About" url="" button_key="about" current_key={props.current_key}/>
            <NavbarButton text="Courses" url="/courses" button_key="courses" current_key={props.current_key}/>
            <NavbarButton text="Contact Us" url="" button_key="contact" current_key={props.current_key}/>
            <NavbarButton text="Redeem" url="" button_key="redeem" current_key={props.current_key}/>
            {/* <NavbarButton text="GCSE MATHS COURSE" url="" button_key="gcsemaths" current_key={props.current_key}/> */}

        </div>
    )
}

export default Navbar;