import { DocumentData } from 'firebase/firestore/lite';
import React from 'react'
import { useParams } from 'react-router-dom'
import Sidebar from '../components/Sidebar';
import { GetCourseTree, updateCourseTree } from '../DatabaseFunctions';
import Modal from 'react-modal';
import { v4 as uuidv4 } from 'uuid';
import "../styles/CourseChapters.css"

type Props = {
    activeUserId: string;
    setShowDefaultSidebar: any;
    setOverlappingSidebar: any;
    setSidebarOpen: any;

}


const customStyles = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
    content: {
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: "180px",
        width: "500px",
        border: "2px solid #353535",
        backgroundColor: "black",
        color: "white",
        overflow: "hidden",
        zIndex: 99999999,
    },
    
};

Modal.setAppElement(document.getElementById('root')!);


const CourseChapters = (props: Props) => {

    React.useEffect(() => {
        props.setSidebarOpen(true);
        props.setShowDefaultSidebar(true);
        props.setOverlappingSidebar(true);
    }, [])


    const { courseId } = useParams();
    const { versionDate } = useParams();
    const { adminAccess } = useParams();
    const [courseTree, setCourseTree] = React.useState<DocumentData[]>([]);
    const [originalCourseTree, setOriginalCourseTree] = React.useState<DocumentData[]>([]);
    const [parentId, setParentId] = React.useState(courseId);
    const [unSavedCategoryChange, setUnsavedCategoryChange] = React.useState(false);

    let subtitle: any;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
      }
    
      function closeModal() {
        setIsOpen(false);
      }


    React.useEffect(() => {
        // Only get Coursetree if user actually has access to the course. Checks in local storage but further check is completed by Firebase.
        if (props.activeUserId !== "" && props.activeUserId !== "unset" && JSON.parse(localStorage.getItem("userCourses-" + props.activeUserId)!)){
            if(JSON.parse(localStorage.getItem("userCourses-" + props.activeUserId)!)[courseId!] !== undefined){
                if(JSON.parse(localStorage.getItem("userCourses-" + props.activeUserId)!)[courseId!]["access"]){
                    GetCourseTree(courseId!, versionDate!).then((result) => {
                        setCourseTree(result);
                        setOriginalCourseTree(result);
                    })
                }
            }
        }
    }, [props.activeUserId])

    React.useEffect(() => {
        if (JSON.stringify(courseTree) !== JSON.stringify(originalCourseTree)) {
            setUnsavedCategoryChange(true)
        }
        else {
            setUnsavedCategoryChange(false)
        }
    }, [courseTree, originalCourseTree])

    React.useEffect(() => {
        // Have a loading state to prevent execution on page render
        if (courseTree.filter(parentFilter).length == 0){
            // console.log("Checking for lessons under this category...")
            // console.log("If lessons exist, page will switch to lesson view")
        }

    }, [parentId])


    const updateParent = (chapter: any) => {
        console.log(chapter);
        if (chapter["hasLessons"] === true){
            if (adminAccess){
                window.location.assign("/courses/id=" + courseId + "/lessons/category=" + chapter.chapterId + "/hasLessons=True/admin=True");
            }
            else {
                window.location.assign("/courses/id=" + courseId + "/lessons/category=" + chapter.chapterId + "/hasLessons=True");
            }
        }
        else if (adminAccess || courseTree.filter((value) => value.parent == chapter.chapterId).length != 0){
            console.log("updating parent to " + chapter.chapterId);
            setParentId(chapter.chapterId);
        }
    }

    const getParent = (childId: string) => {
        for (const node of courseTree) {
            if (node.chapterId === childId) {
                return node.parent;
            }
        }
    }

    const parentFilter = (value: any) => {
        if (value.parent === parentId) {
            return true;
        }
        return false;
    }

    const upLevel = () => {
        if (parentId !== courseId){
            console.log("updating parent to " + getParent(parentId!));
            setParentId(getParent(parentId!));
        }
        else {
            window.location.replace("/");
        }
    }

    const lessonPageRedirect = () => {
        if (adminAccess){
            console.log("Lesson Page redirect")
            if (courseTree.filter((category) => {return category.chapterId === parentId})[0]["hasLessons"] == true){
                window.location.assign("/courses/id=" + courseId + "/lessons/category=" + parentId + "/hasLessons=True/admin=True");
            }
            else {
                window.location.assign("/courses/id=" + courseId + "/lessons/category=" + parentId + "/admin=True");
            }
        }
        else {
            if (courseTree.filter((category) => {return category.chapterId === parentId})[0]["hasLessons"] == true){
                window.location.assign("/courses/id=" + courseId + "/lessons/category="  + parentId + "hasLessons=True");
            }
        }
    }

    const onSave = async () => {
        if (await updateCourseTree(courseId!, courseTree) === "success"){
            setOriginalCourseTree(courseTree);
        }
    }

    const addSection = (e: any) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const newCategoryName = Object.fromEntries(formData.entries())["newCategoryName"];
        const courseTreeClone = JSON.parse(JSON.stringify(courseTree));
        courseTreeClone.push({
                                "chapterId": uuidv4(),
                                "chapterName": newCategoryName,
                                "parent": parentId,
                                "children": false,
                                "index": 99
                             })
        if (parentId !== courseId){
            courseTreeClone.filter((r: any) => {return (r.chapterId === parentId)})[0]["children"] = true;
        }
        setCourseTree(courseTreeClone);
        closeModal();
    }

    const LessonViewButton = () => {
        if (courseTree.filter(parentFilter).length == 0 && !unSavedCategoryChange) {
            if (props.activeUserId !== "" && props.activeUserId !== "unset" ){
                return (
                    <div className="single-button" onClick={lessonPageRedirect}>
                        <span className="s-button-text">Lesson View</span>
                    </div>
                )
            }
        }
    }

    const addSectionButton = () => {
        if (0 == 0) {
            return (
                <div className="single-button button-primary" onClick={openModal}>
                    <span className="s-button-text">New Section</span>
                </div>
            )
        }
    }

    const saveCourseTreeButton = () => {
        if (unSavedCategoryChange) {
            return (
                <div className={"single-button button-primary"} onClick={onSave}>
                    <span className="s-button-text">Save</span>
                </div>
            )
        }
    }

    const adminView = () => {
        if (adminAccess){
            return (
                <div className="admin-controls">
                    { addSectionButton() }
                    { LessonViewButton() }
                    { saveCourseTreeButton() }
                </div>
            )
        }
    }

    let chapterCards = courseTree.filter(parentFilter).map((item: any, index:any) => {
        return (
            <div className="chapter-container" onClick={() => updateParent(item)} key={item.chapterId + "-key"}>
                <span className="chapter-text">{item.chapterName}</span>
            </div>
        )
    })

    return (
        <div>
            {/* <Sidebar current_key="courses"/> */}

            <div className="courseTree-page-container">

                <div className="page-content-wrapper">

                    <div>
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Add Section"
                        >
                            <button onClick={closeModal}>close</button>
                            <div>Section Name</div>
                            <form onSubmit={addSection}>
                                <input name="newCategoryName" />
                                <button type="submit">Submit</button>
                            </form>
                        </Modal>
                    </div>
                    <div className="chapter-grid-container">

                        <div className="chapter-page-buttons">

                            <div className="user-controls">
                                <div className="single-button button-primary" onClick={() => {upLevel()}}>
                                <span className="s-button-text">Back</span>
                                </div>
                            </div>

                            {adminView()}

                        </div>
                        <div className="chapter-grid">
                            {chapterCards}
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )

}

export default CourseChapters;