import "../styles/ProductCardsGrid.css"
import { DocumentData } from 'firebase/firestore/lite';
import React, { useEffect, useState } from 'react'
import { GetCourses, GetTable, GetUserCourses } from '../DatabaseFunctions';
import ProductCard from './ProductCard';
import { CourseItemConfig } from "../enums/CourseItemConfig";
import { utimes } from "fs";


type Props = {
    myCourses: boolean
    coursesData: DocumentData[]
    userId: string
    userAccessFilter: boolean
    userCourseData: any
    isLoading: boolean
    category: string
}


const ProductCardsGridRow = (props: Props) => {




    const userAccessFilter = (value: any) => {
        if (props.userAccessFilter === false) {
            return true;
        }
        else if (Object.keys(props.userCourseData).includes(value.id)) {
            if (props.userCourseData[value.id]["access"] == true){
                return true;
            }
        }
        return false;
    }


    const categoryFilter = (value: any) => {
        if (props.category === "") {
            return true;
        }
        else if (props.category === value.category) {
            return true;
        }
        return false;
    }

    const getUrl = (courseId: string, vd: string) => {
        let outputUrl = ""
        if (Object.keys(props.userCourseData).includes(courseId)) {
            outputUrl = "/courses/id=" + courseId;
            outputUrl += "/vd=" + vd;
        }
        else {
            outputUrl = "/courses/buyId=" + courseId;
        }

        if (props.userCourseData[courseId] !== undefined){
            if (props.userCourseData[courseId]["admin"]) {
                outputUrl += "/admin=True"
            }
        }

        return outputUrl;
    }




    let itemList = props.coursesData.filter(x => x.userShow).filter(userAccessFilter).filter(categoryFilter).map((item: any, index: any) => {
        return (
            <ProductCard key={index}
                id={item.id}
                productName={item.title}
                description={item.description}
                price={item.price}
                userCourseData={props.userCourseData}
                totalComponents = {item.totalComponents}
                thumbnailUrl={item.thumbnailUrl}
                url={getUrl(item.id, item.vd)}
                cardColor={item.cardColor}
                titleColor={item.titleColor}
                myCourses={props.myCourses}
                activeUserId={props.userId}
            />
        )
    })


    if (itemList.length != 0) {
        return (
            <div>
                {itemList}
            </div>
        )
    }
    else if (props.isLoading) {
        return (
            <div className="no-courses-error">
                <p>Loading...</p>
            </div>
        )
    }
    else if (props.userId != "") {
        return (
            <div className="no-courses-error">
                <p>You have not subscribed to any courses yet.</p>
            </div>
        )
    }
    else {
        return (
            <div className="no-courses-error">
                <p>Please login to see your courses.</p>
            </div>
        )
    }
}

export default ProductCardsGridRow