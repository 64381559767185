import React from "react";
import SidebarButton from "./SidebarButton";


type Props = {
    showLessonBar: boolean;
    showToolBar: boolean;
    showMainContent: boolean;
    setShowLessonBar: any;
    setShowToolBar: any;
    setShowMainContent: any;
}

const LessonMobileBar = (props: Props) => {

    const activateLessonBar = () => {
        props.setShowLessonBar(true);
        props.setShowMainContent(false);
        props.setShowToolBar(false);
    }

    const activateToolBar = () => {
        props.setShowLessonBar(false);
        props.setShowMainContent(false);
        props.setShowToolBar(true);
    }

    const activateMainContent = () => {
        props.setShowLessonBar(false);
        props.setShowMainContent(true);
        props.setShowToolBar(false);
    }

    React.useEffect(() => {console.log(props.showLessonBar)}, [props.showLessonBar]);

    return (
        <div className="menu-bar">
            <button className={props.showLessonBar ? "active-btn" : "inactive-btn"} onClick={activateLessonBar}>Contents</button>
            <button className={props.showMainContent ? "active-btn" : "inactive-btn"} onClick={activateMainContent}>Lesson</button>
            <button className={props.showToolBar ? "active-btn" : "inactive-btn"} onClick={activateToolBar}>Tools</button>
        </div>
    )
}

export default LessonMobileBar;