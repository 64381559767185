import { useState } from 'react';
import '../styles/Topbar.css'
import { SignIn } from './SignIn';
import { FaBars, FaSun } from "react-icons/fa";
import Logo from './Logo';


type Props = {
    setActiveUserId: any,
    sidebarOpen: boolean,
    setSidebarOpen: any,
    showSidebarToggle: boolean,
}

const Topbar = (props: Props) => {

    const onHamburgerClick = () => {
        props.setSidebarOpen(!props.sidebarOpen);
    }


    return (
        <div className="top-bar">
            <div className="hamburger-logo-container">
                {props.showSidebarToggle && <div className="topbar-icon" onClick={onHamburgerClick}><FaBars /></div>}
                {!props.showSidebarToggle && <span className="topbar-icon">&nbsp;</span>}
                <Logo />
            </div>


            <div className="options-container">
                <SignIn setActiveUserId={props.setActiveUserId} />
            </div>
            
        </div>

    )
}

export default Topbar;