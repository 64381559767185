import React from "react"
import { User, getAuth, onAuthStateChanged, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";

import { SignInWithEmail, SignUpWithEmail, processFirebaseError } from "./SignIn"
import "../styles/Authenticate.css"
import Logo from "./Logo";


type Props = {
    redirectUrl: string;
    checkVerify?: boolean;
}

const Authenticate = (props: Props) => {

    const [view, setView] = React.useState("login");
    const [errors, setErrors] = React.useState<string[]>([])

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("")
    const [passwordCheck, setPasswordCheck] = React.useState("")

    const [user, setUser] = React.useState<User | null>(null);

    React.useEffect(() => {
        setErrors([]);
    }, [view])

    React.useEffect(() => {

        let auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                if (!user.emailVerified) {
                    // window.location.href="/notVerified"
                    setView("emailVerificationForm");
                }
                else {
                    setView("loggedIn")
                    if (props.redirectUrl !== "") {
                        window.location.href = props.redirectUrl;
                    }
                    else {
                        window.location.href =  "/";
                    }
                }
            }
          });
      
          return () => unsubscribe();
      
    }, [])


    const sendVerificationEmail = (e: any) => {
        e.preventDefault();
        if (user) {
            if (!user.emailVerified) {
                //TODO: Make sure a verification email was not recently sent. Use local storage?

                if ((window.location.hostname).includes("localhost")){
                    sendEmailVerification(user);
                }
                else {
                    let hostUrl = window.location.hostname;
                    if (!hostUrl.includes("http")) {
                        hostUrl = "https://" + hostUrl;
                    }
                    sendEmailVerification(user, {url: hostUrl});
                }
                setView("emailVerificationMessage")
            }
        }
    }

    const sendPasswordResetLink = (e: any) => {
        e.preventDefault();
        let auth = getAuth();
        sendPasswordResetEmail(auth, email).then((result) => {
            setView("passwordResetMessage")
        }).catch((error) => {
            setErrors(["ERROR: " + processFirebaseError(error.message)])
        });
    }

    const executeLogin = (e: any) => {
        e.preventDefault();
        SignInWithEmail(email, password, "/").then((result) => {
            if (result === "NOT_VERIFIED"){
                setView("emailVerificationForm")
            }
            if (result.includes("ERROR")) {
                setErrors([result]);
            }
        })

    }

    const executeSignUp = (e: any) => {
        e.preventDefault();
        if (password === passwordCheck) {
            SignUpWithEmail(email, password).then((result) => {
                if (result.includes("ERROR")) {
                    setErrors([result]);
                }
            })
        }
        else {
            setErrors(errors => ["ERROR: Passwords do not match"])
        }
    }


    const onAuthenticated = () => {
        if (props.redirectUrl != ""){
            window.self.close();
            // window.location.href = props.redirectUrl;
        }
    }

    const loginForm = () => {


        return (
            <form className="auth-form" onSubmit={executeLogin}>
                <h1>Login</h1>
                <input className="text-input" type="email" placeholder="Email Address" required={true} onChange={(e) => setEmail(e.target.value)}/>
                <input className="text-input" type="password" placeholder="Password" required={true} onChange={(e) => setPassword(e.target.value)}/>
                <span className="text-link" onClick={() => setView("forgotPassword")}>Forgot Password?</span>


                <button type="submit" className="submit-button">
                    Login
                </button>

            </form>
        )
    }

    const forgottenForm = () => {

        return (
            <form className="auth-form"  onSubmit={sendPasswordResetLink}>
                <h1>Forgot Password</h1>
                <span style={{fontSize: "small", marginBottom: "5%"}}>Please enter your email below to reset your password </span>
                <input className="text-input" type="email" required={true} placeholder="Email Address"  onChange={(e) => setEmail(e.target.value)}/>


                <button type="submit" className="submit-button">
                    Submit
                </button>

            </form>
        )
    }


    const emailVerificationMessage = () => {
        return (
            <form className="auth-form">
                <h1>Email Verification</h1>
                <span style={{fontSize: "small", marginBottom: "5%"}}>A verification link has been sent to your email. Please click it to activate your account.</span>
                <button className="submit-button" type="submit" onSubmit={()=>(window.location.href="/auth/verify")}>
                    Done
                </button>            
            </form>
        )
    }

    const passwordResetMessage = () => {
        return (
            <div className="auth-form">
                <h1>Password Reset</h1>
                <span style={{fontSize: "small", marginBottom: "5%"}}>A password reset link has been sent to your email. Please check your inbox and spam folders.</span>
                <button className="submit-button" onClick={()=>(window.location.href="/auth")}>
                    Done
                </button>            
            </div>
        )
    }

    const emailVerificationForm = () => {
        return (
            <form className="auth-form" onSubmit={sendVerificationEmail}>
                <h1>Verify your email</h1>
                <span style={{fontSize: "small", marginBottom: "5%"}}>Click the button below to send a verification email to {user!.email}.</span>
                <button className="submit-button">Send Email</button>
            </form>
        )
    }

    const signUpForm = () => {

        return (
            <form className="auth-form" onSubmit={executeSignUp}>
                <h1>Sign Up</h1>
                <input className="text-input" type="email" required={true} placeholder="Email Address"  onChange={(e) => setEmail(e.target.value)}/>
                <input className="text-input" type="password" required={true} minLength={8} placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                <input className="text-input" type="password" required={true} minLength={8} placeholder="Verify Password" onChange={(e) => setPasswordCheck(e.target.value)}/>


                <button type="submit" className="submit-button">
                    Sign Up
                </button>

            </form>
        )
    }

    const loggedInView = () => {

        return (
            <div className="auth-form">
                <h1>Logged in Successfully</h1>
                <span style={{fontSize: "small", marginBottom: "5%"}}>You are successfully logged in as: {user!.email}.</span>
                <button className="submit-button" onClick={() => window.self.close()}>Go to Home Page</button>
            </div>
        )
    }

    const authToggle = () => {
        return(
            <div className="auth-toggle">
                <div className={view!=="signUp" ? "toggle-button selected-color" : "toggle-button"} onClick={()=>{if (view!=="loggedIn") setView("login")}}>
                    Login
                </div>
                <div className={view==="signUp" ? "toggle-button selected-color" : "toggle-button"} onClick={()=>{if (view!=="loggedIn") setView("signUp")}}>
                    Sign-up
                </div>
            </div>
        )
    }

    const display = () => {
        
        return (
            <>

                {view==="login" ? loginForm() : ""}
                {view==="signUp" ? signUpForm() : ""}
                {view==="forgotPassword" ? forgottenForm() : ""}
                {view==="passwordResetMessage" ? passwordResetMessage() : ""}
                {view==="emailVerificationForm" ? emailVerificationForm() : ""}
                {view==="emailVerificationMessage" ? emailVerificationMessage() : ""}
                {view==="loggedIn" ? loggedInView() : ""}
                {errorMessages()}
            </>
        )
    }

    const errorMessages = () => {
        return errors.map((item: any, index:any)=>{
            return (
                <p key={index} style={{color: "red", fontSize: "small"}}>{item}</p>
            )
        })
        
    }

    const render = () => {
        if (user) {
            if (user.emailVerified) {
    
                return (
                    <div className="auth-container">
                        <div className="auth-content">
                            {view==="loggedIn" ? loggedInView() : ""}
                        </div>
                    </div>
                )
            }
    
            else {
                if (!props.checkVerify){
                    return (
                        <div className="auth-container">
                            <div className="auth-content">
                                {display()}
                            </div>
                        </div>
                    )
                }
                else {
                    return (
                        <div className="auth-container">
                            <div className="auth-content">
                                {emailVerificationMessage()}
                            </div>
                        </div>
                    )
                }
            }
        }
        else {
            return (
                <div className="auth-container">
                        <div style={{backgroundColor: "black", width: "100%", margin: "0 auto", display: "flex", alignItems: "center", justifyContent: "center"}}>
                            
                            <div style={{}}><Logo /></div>
                        </div>
                    <div className="auth-content">

                        {authToggle()}
                        {display()}
                    </div>
                </div>
            )
        }

    }


    return (
        <div className="authpage-container" style={{backgroundImage: "url(https://live.staticflickr.com/5012/5506441436_e4530a1749.jpg)"}}>
            { render() }
        </div>
    )



    
    
}

export default Authenticate;