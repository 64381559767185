import Modal from "react-modal";
import { Document, Page } from 'react-pdf';

type Props = {
  viewPdfPopup: boolean;
  setViewPdfPopup: any;
  pdfUrl: string;
}


const ViewPdfPopup = (props: Props) => {



    return (
        <div>
            <Modal
                isOpen={props.viewPdfPopup}
                onRequestClose={() => props.setViewPdfPopup(false)}
                style={{
                overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                },
                content: {
                    top: "52%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "80%",
                    width: "60%",
                    border: "2px solid #353535",
                    backgroundColor: "black",
                    color: "white",
                    overflow: "hidden",
                    zIndex: 99999999,
                },
                }}
            >
                
                <iframe 
                    style={{width:"100%", height:"100%"}}
                    src={props.pdfUrl + "#toolbar=0"}
                />

                {/* <Document file={props.pdfUrl + "#toolbar=0"}>
                    <Page pageNumber={1} />
                </Document> */}

            </Modal>
        </div>
    )


}

export default ViewPdfPopup;