import React from "react";
import { storage } from "../Firebase";
import UploadResourcePopup from "./UploadResourcePopup";
import { ref, updateMetadata, getDownloadURL, connectStorageEmulator } from 'firebase/storage';
import ViewPdfPopup from "./ViewPdfPopup";
import LessonButtonContextMenu from "./LessonButtonContextMenu";
import PopupAlert from "./PopupAlert";
import { deleteResource } from "../StorageFunctionsAdmin";

type Props = {
    adminAccess: boolean;
    currentLessonData: any;
    lessonId: any;
    courseId: any;
    updateLesson: any;
}


const LessonResourcesCard = (props: Props) => {

    const [uploadPopupStatus, setUploadPopupStatus] = React.useState({show: false});
    const [viewPdfPopup, setViewPdfPopup] = React.useState(false);
    const [pdfUrl, setPdfUrl] = React.useState("");const [contextMenuPosX, setPosX] = React.useState("");
    const [contextMenuPosY, setPosY] = React.useState("");
    const [contextMenu, setContextMenu] = React.useState(false);
    const [rightClickedId, setRightClickedId] = React.useState("");
    const [deletePopupStatus, setDeletePopupStatus] = React.useState({show: false, id: "nullString"})

    React.useEffect(() => {

        const handleClick = () => {
            if (contextMenu){
                setContextMenu(false)
                setRightClickedId("");
                window.removeEventListener('click', handleClick);
            }
        }
        
        if (contextMenu){
            window.addEventListener('click', handleClick);
        }

    }, [contextMenu])

    

    const handleRightClick = (event: any) => {
        event.preventDefault();
        if (event.target.id !== ""){
            setPosX(event.pageX + "px");
            setPosY(event.pageY + "px");
            setContextMenu(true);
            setRightClickedId(event.target.id);
        }
    }

    const handleDelete = () => {
        setDeletePopupStatus({show: true, id: rightClickedId});
    }

    const handleDeleteImpl = (deleteId: string) => {        

        const currentResourceMetadata = props.currentLessonData.resources.filter((resource: any) => resource.fileId === deleteId)[0];
        console.log(currentResourceMetadata);
        deleteResource(currentResourceMetadata.root, currentResourceMetadata.fileId).then((result) => {
            if (result === "Success") {
                const currentLessonContentClone = JSON.parse(JSON.stringify(props.currentLessonData));
                currentLessonContentClone.resources = props.currentLessonData.resources.filter((resource: any) => resource.fileId !== deleteId);
                props.updateLesson(props.lessonId, currentLessonContentClone)
                setDeletePopupStatus({show: false, id: "nullString"});
            }
        })
    }

    const generateAuthenticatedUrl = async (fileRef: string) => {
        let now = new Date();
        if (parseInt(localStorage.getItem(`url-expiry${fileRef}`)!) < now.getTime() || localStorage.getItem(`url${fileRef}`) === null){
            console.log("getting url from firebase")
            const storageRef = ref(storage, fileRef);
            const url = await getDownloadURL(storageRef);
            let expiryDate = new Date(now.getTime() + 20 * 60000);
            localStorage.setItem(`url-expiry${fileRef}`, expiryDate.getTime().toString())
            localStorage.setItem(`url${fileRef}`, url)
            return url;
        }
        else {
            console.log("getting url from local storage")
            const url = localStorage.getItem(`url${fileRef}`)!;
            return url;
        }
    };

    const onResourceClick = (resource: any) => {
        generateAuthenticatedUrl(resource.root + resource.fileId).then((result) => {
            setViewPdfPopup(true);
            setPdfUrl(result);
        }).catch((error) => {
            console.log(error)
        })

    }

    const getLessonResources = () => {
        if (Array.isArray(props.currentLessonData.resources)) {
            const lessonResources = props.currentLessonData.resources.map((resource:any, index: number) => {
                return (
                    <a id={resource.fileId} key={resource.fileId} style={{cursor:"pointer"}} onClick={() => onResourceClick(resource)} onContextMenu={handleRightClick}> {resource.alias} </a>
                )
            })
            return lessonResources;
        }
    }

    return (
        <div className="lesson-card">
            <div className="card-title">
                <p>Lesson Resources</p>
                {props.adminAccess && <button className="admin-resources-button button-primary" style={{marginLeft: "10px"}} onClick={() => setUploadPopupStatus({show:true})}><p>Add</p></button>}
            </div>
            <UploadResourcePopup 
                currentLessonData={props.currentLessonData}
                lessonId={props.lessonId}
                courseId={props.courseId}
                updateLesson={props.updateLesson}
                uploadPopupStatus={uploadPopupStatus} 
                setUploadPopupStatus={setUploadPopupStatus}
                onConfirm = {() => {}}
            />

            <ViewPdfPopup
                viewPdfPopup={viewPdfPopup}
                setViewPdfPopup={setViewPdfPopup}
                pdfUrl={pdfUrl}
            />

            <PopupAlert deletePopupStatus={deletePopupStatus} setDeletePopupStatus={setDeletePopupStatus} onConfirm={handleDeleteImpl}/>


            <div className="lesson-resources">
                {getLessonResources()}
            </div>

            {props.adminAccess && contextMenu && <LessonButtonContextMenu show={contextMenu} setContextMenu={setContextMenu} handleEdit={null} handleDelete={handleDelete} style={{ left: contextMenuPosX, top: contextMenuPosY}}/>}

        </div>
    )
}

export default LessonResourcesCard;