import Modal from "react-modal";
import React, { useState, ChangeEvent } from 'react';
import 'firebase/compat/storage';
import { storage } from '../Firebase';
import { ref, uploadBytesResumable } from 'firebase/storage';
import {v4 as uuidv4 } from 'uuid';
import { uploadResource } from "../StorageFunctionsAdmin";

type Props = {
  currentLessonData: any;
  lessonId: any;
  courseId: any;
  updateLesson: any;
  uploadPopupStatus: any;
  setUploadPopupStatus: any;
  onConfirm: any;
}


const UploadResourcePopup = (props: Props) => {

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileAlias, setFileAlias] = useState("")

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      setSelectedFile(file || null);
    };
  
    const handleUpload = () => {

      if (selectedFile) {
        const directory = `/files/${props.courseId}/`;
        const fileId = uuidv4();

        uploadResource(directory, fileId, selectedFile).then((result) => {
          if (result === "Success"){
            console.log("File uploaded successfully")

            const currentLessonContentClone = JSON.parse(JSON.stringify(props.currentLessonData));
            if (!Array.isArray(currentLessonContentClone["resources"])) {
              currentLessonContentClone["resources"] = [];
            }
            console.log("COURSEID: " + props.courseId)
            currentLessonContentClone["resources"].push({"root": directory, "fileId": fileId, "name": selectedFile.name, "alias": (fileAlias === "" ? selectedFile.name : fileAlias)});

            props.updateLesson(props.lessonId, currentLessonContentClone);
            props.setUploadPopupStatus({show: false});
            setFileAlias("");
            setSelectedFile(null);
          }
        })

        
      }
    };

    return (
        <div>
            <Modal
                isOpen={props.uploadPopupStatus.show}
                onRequestClose={() => props.setUploadPopupStatus({show: false, id: "nullString"})}
                style={{
                overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                },
                content: {
                    top: "30%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "200px",
                    width: "500px",
                    border: "2px solid #353535",
                    backgroundColor: "black",
                    color: "white",
                    overflow: "hidden",
                    zIndex: 99999999,
                },
                }}
            >
                <h2>Upload File</h2>
                <input type="text" placeholder="Enter File Label" onChange={(e) => setFileAlias(e.target.value)} />
                <br/>
                <br/>
                <input type="file" accept="application/pdf" onChange={handleFileChange} />
                <br/>
                <button className={"admin-content-button bg-red"} onClick={() => props.setUploadPopupStatus({show: false, id: "nullString"})}><p>Cancel</p></button>
                &nbsp; 
                
                {selectedFile && <button className={"admin-content-button bg-green"} onClick={handleUpload}><p>Confirm</p></button>}
            </Modal>
        </div>
    )


}

export default UploadResourcePopup;