import React, { useState } from 'react';
import {BrowserRouter,Routes, Route} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import CoursesPage from './pages/Courses';
import CourseChapters from './pages/CourseChapters';
import Topbar from './components/Topbar';
import DefaultSidebar from './components/DefaultSidebar';
import LessonView from './pages/LessonView';
import HomePage from './pages/HomePage';
import Authenticate from './components/Authenticate';
import Pages from './Pages';
import AuthAction from './pages/AuthAction';

function App() {


  return (
    <div className="App">



      <BrowserRouter>

        <Routes>
            <Route path="/" element={<Pages />} />
            <Route path="/courses/*" element={<Pages />} />
            <Route path="/auth" element={<Authenticate redirectUrl='/'/>} />
            <Route path="/auth/verify" element={<Authenticate redirectUrl='/' checkVerify={true}/>} />
            <Route path="/authAction" element={<AuthAction />} />
        </Routes>
 
      </BrowserRouter>


    </div>
  );
}

export default App;
