import { DocumentData } from "firebase/firestore/lite";
import React, { useEffect, useState } from "react";
import { GetCourses, GetUserCourses } from "../DatabaseFunctions";
import { CourseItemConfig } from "../enums/CourseItemConfig";
import ProductCardsGridRow from "./ProductCardsGridRow"

type Props = {
    activeUserId: string,
}


const ProductCardsGrid = (props: Props) => {

    const [userCourseData, setUserCourseData] = useState({});
    const [coursesData, setCoursesData] = useState<DocumentData[]>([]);
    const [categoryList, setCategoryList] = useState<String[]>([]);
    const [allCoursesLoading, setAllCoursesLoading] = useState(true);
    const [enrolledCoursesLoading, setEnrolledCoursesLoading] = useState(false);


    useEffect(() => {
        setAllCoursesLoading(true);
        GetCourses(CourseItemConfig.showAll).then((result)=>{
            setCoursesData(result);
            setAllCoursesLoading(false);
        });
        if (props.activeUserId != ""){
            setEnrolledCoursesLoading(true);
            GetUserCourses(props.activeUserId).then((result)=>{
                setUserCourseData(result);
                setEnrolledCoursesLoading(false);
            })
        }
        else {
            setUserCourseData({});
        }
    },[props.activeUserId])


    useEffect(() => {

        let categorySet = new Set<string>();
        for (const course of coursesData){
            categorySet.add(course.category)
        }
        setCategoryList(Array.from(categorySet));

    }, [coursesData])



    let categories=categoryList.map((item: any, index:any)=>{
        return(

            <div key={item + "-key"}>

                <div className="title-bar">
                    <h2> {item} </h2>
                </div>

                <div className="cards-grid">
                    <ProductCardsGridRow 
                        myCourses={false} 
                        coursesData={coursesData}
                        userId={props.activeUserId}
                        userAccessFilter={false}
                        userCourseData={userCourseData} 
                        isLoading={allCoursesLoading}
                        category={item}
                    />
                </div>

            </div>

        )
    })



    return (
        <div>
            {/* <div className="title-bar">
                <h2> My Courses </h2>
            </div> */}

        {((props.activeUserId == "unset") || (props.activeUserId !== "" && JSON.stringify(userCourseData) == "{}")) && (
                
                <div style={{display:"flex", justifyContent: "center", alignItems: "center", marginBottom: "5%"}}>
                    <span>Loading &nbsp;</span><span className="loader"></span>
                </div> 
        
            )}

            {(props.activeUserId !== "unset") && !(props.activeUserId !== "" && JSON.stringify(userCourseData) == "{}") && (
            
                <div className="cards-grid">
                    <ProductCardsGridRow 
                        myCourses={false} 
                        coursesData={coursesData}
                        userId={props.activeUserId}
                        userAccessFilter={false}
                        userCourseData={userCourseData} 
                        isLoading={enrolledCoursesLoading}
                        category={""}
                    />
                </div> 
        
            )}




            {/* {categories} */}

        </div>
    )

}

export default ProductCardsGrid;