import React, { useState } from 'react'
import app from './Firebase'
import { getFirestore, doc, collection, getDoc, getDocs, setDoc, connectFirestoreEmulator, DocumentData, updateDoc } from 'firebase/firestore/lite';
import courses from "./data/courses.json";
import { CourseItemConfig } from './enums/CourseItemConfig';

const yaml = require('js-yaml');

const db = getFirestore(app);


export async function GetTable(tableName: string) {
    console.log("Database read: GetTable call")
    const allCol = collection(db, tableName);
    const snapshot = await getDocs(allCol);
    let results: DocumentData[];
    results = [];
    //@ts-ignore
    const data = snapshot.docs;

    for (const course of data) {
        let courseData = course.data()
        //@ts-ignore
        let key_array = course["_key"]["path"]["segments"];
        courseData["id"] = key_array[key_array.length - 1];
        results.push(courseData);
    }

    return results;
}

export async function GetCourses(config: CourseItemConfig, userId: string = "") {
    let courseList: DocumentData[];
    courseList = [];

    for (const course of courses) {
        if (config === CourseItemConfig.showAll){
            courseList.push(course);
        }
    }
    return courseList;
}

export async function updateCourseTree(courseId: string, newCourseTree: any) {
    try {
        const courseRef = doc(db, "courseTree/" + courseId)
        const snapshot = await setDoc(courseRef, {"data": newCourseTree});
        localStorage.setItem("courseTree-" + courseId, JSON.stringify(newCourseTree));
        console.log("Database write: updateCourseTree call")
        return "success";
    }
    catch(err) {
        console.log(err);
        return "Failed";
    }
}

export async function GetCourseTree(courseId: string, versionDate: string) {
    var now = new Date();
    const localStorageLastUpdated = localStorage.getItem("courseTree-" + courseId + "-lastUpdated")
    if (localStorage.getItem("courseTree-" + courseId)) {
        if (parseInt(localStorageLastUpdated!) > parseInt(versionDate)){
            console.log("Getting courseTree from local storage")
            return JSON.parse(localStorage.getItem("courseTree-" + courseId)!);
        }
    }

    const courseRef = doc(db, "courseTree/" + courseId)
    const snapshot = await getDoc(courseRef);
    console.log("Database read: GetCourseTree call")
    const data = snapshot.data()!;
    localStorage.setItem("courseTree-" + courseId, JSON.stringify(data["data"]));
    localStorage.setItem("courseTree-" + courseId + "-lastUpdated", now.getTime().toString());
    return JSON.parse(JSON.stringify(data["data"]));
}

// Takes user ID and returns a list of course IDs
export async function GetUserCourses(userId: string){
    if (userId != "" && userId !== "unset"){
        var now = new Date();

        if ( (localStorage.getItem("userCourses-" + userId) && (now.getTime() < parseInt(localStorage.getItem("userCourses-" + userId + "-expiry")!)))){
            console.log("Getting user access from local storage...");
            return JSON.parse(localStorage.getItem("userCourses-" + userId)!);
        }
        else {
            console.log("Database read: GetUserCourses call")
            const docRef = doc(db, "usercourses/" + userId)
            const snapshot = await getDoc(docRef);

            let expiryDate = new Date(now.getTime() + 5 * 60000);
            localStorage.setItem("userCourses-" + userId + "-expiry", expiryDate.getTime().toString())

            if (snapshot.data() !== undefined){
                localStorage.setItem("userCourses-" + userId, JSON.stringify(snapshot.data()));
                return JSON.parse(JSON.stringify(snapshot.data()));
            }
            else {
                localStorage.setItem("userCourses-" + userId, "{}");
                return {}
            }
        }
    }
    else {
        return [];
    }
}

export async function GetUserLessonStatus(userId: string, categoryId:string, courseId: string) {
    var now = new Date();
    var userCourseData;

    if ((localStorage.getItem("userLessonStatus-" + userId + "_" + courseId) && (now.getTime() < parseInt(localStorage.getItem("userLessonStatus-" + userId + "_" + courseId + "-expiry")!)))){
        console.log("Getting user lesson status from local storage...");
        console.log("expiry in: " + (parseInt(localStorage.getItem("userLessonStatus-" + userId + "_" + courseId + "-expiry")!) - now.getTime())/1000);
        userCourseData = JSON.parse(localStorage.getItem("userLessonStatus-" + userId + "_" + courseId)!);
    }
    else {
        console.log("Database read: GetUserLessonStatus call")
        const docRef = doc(db, `userLessonStatus/${userId}_${courseId}`);
        const snapshot = await getDoc(docRef);
        let expiryDate = new Date(now.getTime() + 5 * 60000);
        localStorage.setItem("userLessonStatus-" + userId + "_" + courseId + "-expiry", expiryDate.getTime().toString())
        if (snapshot.data() !== undefined){
            userCourseData = JSON.parse(JSON.stringify(snapshot.data()));
            localStorage.setItem("userLessonStatus-" + userId + "_" + courseId, JSON.stringify(userCourseData));
        }
        else {
            userCourseData = {}
            localStorage.setItem("userLessonStatus-" + userId + "_" + courseId, JSON.stringify({}));
        }
    }

    if (userCourseData[categoryId] !== undefined){
        return userCourseData[categoryId];
    }
    else {
        return {};
    }
}

export async function UpdateUserLessonStatus(userLessonStatus: any, userId: string, categoryId: string, courseId: string) {
    let userCourseData = JSON.parse(localStorage.getItem("userLessonStatus-" + userId + "_" + courseId)!);
    userCourseData[categoryId] = userLessonStatus;
    console.log("Database write: updateUserLessonStatus call")
    const userRef = doc(db, `userLessonStatus/${userId}_${courseId}`);

    try {
        if (localStorage.getItem("userLessonStatus-" + userId + "_" + courseId) !== "{}"){
            const snapshot = await updateDoc(userRef, {[categoryId]: userLessonStatus})
        }else {
            const snapshot = await setDoc(userRef, userCourseData)
        }
        localStorage.setItem("userLessonStatus-" + userId + "_" + courseId, JSON.stringify(userCourseData));
        return "Success updating lesson status";
    }
    catch (err) {
        console.log(err);
        return "Failed";
    }
}

export async function GetCategoryLessons(categoryId: string) {
    console.log("Database read: GetCategoryLessons call")
    const categoryRef = doc(db, "lessonCategories/" + categoryId)
    const snapshot = await getDoc(categoryRef);
    const data = snapshot.data()!;
    if (snapshot.data() !== undefined) {
        return JSON.parse(JSON.stringify(data["lessons"]));
    }
    else {
        return [];
    }
}


export async function updateSingleLesson(lessonData: any, lessonId: string, courseId: string, categoryId: string, newLessonObject: any){
    const newCategoryContentObj :any = [];

    for (const lesson of lessonData){
        if (lesson.id !== lessonId) {
            newCategoryContentObj.push(lesson);
        }
        else {
            newCategoryContentObj.push(newLessonObject);
        }
    }

    try {
        console.log("Database write: updateSingleLesson call")
        const courseRef = doc(db, "lessonCategories/" + categoryId)
        const snapshot = await setDoc(courseRef, {"lessons": newCategoryContentObj, "courseId": courseId});
        return newCategoryContentObj;
    }
    catch(err) {
        console.log(err);
        return "Failed";
    }

}

export async function updateCategoryLessons(courseId: string, categoryId: string, updatedTable: any) {
    try {
        console.log("Database write: updateLessonCategories call")
        const courseRef = doc(db, "lessonCategories/" + categoryId)
        const snapshot = await setDoc(courseRef, {"lessons": updatedTable, "courseId": courseId});
        return "Success";
    }
    catch(err) {
        console.log(err);
        return "Failed";
    }
}


/** Deals with situation where the relevant category did not have any lessons. */
export async function MarkCategoryLessonFlag(courseId: string, categoryId: string, userId: string) {
    console.log("Database Write: MarkCategoryLessonFlag call")
    const categoryRef = doc(db, "courseTree/" + courseId)
    const snapshot = await getDoc(categoryRef);
    const data = snapshot.data()!;
    if (snapshot.data() !== undefined) {
        let courseTree = JSON.parse(JSON.stringify(data["data"]));
        for (let i=0; i<courseTree.length; i++){
            let category = courseTree[i]
            if (category.chapterId === categoryId){
                courseTree[i]["hasLessons"] = true;
                updateCourseTree(courseId, courseTree);
                localStorage.setItem("CourseTree-" + courseId, JSON.stringify(courseTree));
            }
        }
    }
    else {
        return [];
    }
}


export const GetUserData = () => {
    return {"b" : 2}
}

