import { connectFirestoreEmulator } from "firebase/firestore/lite";
import React from "react";
import DraggableLessonButton from "./DraggableLessonButton";
import DraggableLessonButtonList from "./DraggableLessonButtonList"
import LessonButtonContextMenu from "./LessonButtonContextMenu";
import PopupAlert from "./PopupAlert";

type Props = {
    adminAccess: boolean;
    addingLesson: boolean;
    lessonData: any;
    setLessonData: any;
    lastLessonRef: any;
    searchFilter: any;
    getLessonStatusStyle: any;
    getSelectedLessonStyle: any;
    currentLesson: string;
    setSelectedLesson: any;
    updateLessonTable: any;
    editorEnabled: boolean;
    setEditorEnabled: any;
    goToLessonViewMobile: any;
}


const LessonButtonList = (props: Props) => {

    const [contextMenuPosX, setPosX] = React.useState("");
    const [contextMenuPosY, setPosY] = React.useState("");
    const [contextMenu, setContextMenu] = React.useState(false);
    const [rightClickedId, setRightClickedId] = React.useState("");
    const [deletePopupStatus, setDeletePopupStatus] = React.useState({show: false, id: "nullString"})

    React.useEffect(() => {

        const handleClick = () => {
            if (contextMenu){
                setContextMenu(false)
                setRightClickedId("");
                window.removeEventListener('click', handleClick);
            }
        }
        
        if (contextMenu){
            window.addEventListener('click', handleClick);
        }

    }, [contextMenu])

    

    const handleRightClick = (event: any) => {
        event.preventDefault();
        if (event.target.id !== ""){
            setPosX(event.pageX + "px");
            setPosY(event.pageY + "px");
            setContextMenu(true);
            setRightClickedId(event.target.id);
        }
        // console.log('Right-clicked:' + lessonId);
    }


    const handleEdit = () => {
        props.setSelectedLesson(rightClickedId);
        setTimeout(() => {
            props.setEditorEnabled(true);
        }, 1);
        
    }

    const handleDelete = () => {
        props.setEditorEnabled(false);
        setDeletePopupStatus({show: true, id: rightClickedId});
    }

    const handleDeleteImpl = (deleteId: string) => {
        if (deleteId !== "nullString"){
            console.log("handling delete")
            const updatedLessonTable = props.lessonData.filter((item: any) => item.id !== deleteId);
            props.updateLessonTable(updatedLessonTable);
            setDeletePopupStatus({show: false, id: "nullString"});
            if (props.currentLesson === deleteId && updatedLessonTable.length >= 1){
                console.log("deleted current lesson")
                props.setSelectedLesson(updatedLessonTable[updatedLessonTable.length-1].id);
            }
        }
    }


    let lessonButtons: {} | null | undefined;

    if (props.lessonData !== undefined){

        lessonButtons = props.lessonData.map((lesson:any, index: number) => {
            if(props.searchFilter(lesson)){
                return (
                    <DraggableLessonButton
                        key = {index}
                        lessonStatusStyle={props.getLessonStatusStyle(lesson.id)!}
                        selectedLessonStyle={props.getSelectedLessonStyle(lesson.id)}
                        setSelectedLesson={props.setSelectedLesson}
                        setEditorEnabled={props.setEditorEnabled}
                        lesson={lesson}
                        index={index}
                        lastLessonRef = {(index+1 == props.lessonData.length) ? props.lastLessonRef : 0}
                        handleRightClick={handleRightClick}
                        isRightClicked={rightClickedId === lesson.id}
                        goToLessonViewMobile={props.goToLessonViewMobile}
                    />
                )
            }
        })
    }






    if (props.adminAccess && !props.addingLesson && !props.editorEnabled) {
        return (

            <div className={props.adminAccess ? "lesson-button-list-admin" : "lesson-button-list-user"} style={contextMenu ? {overflow: "hidden"} : {overflowY: "scroll"}}>
            <PopupAlert deletePopupStatus={deletePopupStatus} setDeletePopupStatus={setDeletePopupStatus} onConfirm={handleDeleteImpl}/>
            <DraggableLessonButtonList 
                lessonData = {props.lessonData}
                setLessonData = {props.setLessonData} 
                searchFilter = {props.searchFilter} 
                getLessonStatusStyle = {props.getLessonStatusStyle} 
                getSelectedLessonStyle = {props.getSelectedLessonStyle} 
                setSelectedLesson = {props.setSelectedLesson}
                setEditorEnabled = {props.setEditorEnabled}
                lastLessonRef = {props.lastLessonRef}
                handleRightClick={handleRightClick}
                rightClickedId={rightClickedId}
                goToLessonViewMobile={props.goToLessonViewMobile}
            />

            {contextMenu && <LessonButtonContextMenu show={contextMenu} setContextMenu={setContextMenu} handleEdit={handleEdit} handleDelete={handleDelete} style={{ left: contextMenuPosX, top: contextMenuPosY}}/>}
            </div>
        )
    }

    else {
        return (
            <div className={props.adminAccess ? "lesson-button-list-admin" : "lesson-button-list-user"} style={contextMenu ? {overflow: "hidden"} : {overflowY: "scroll"}}>
                {lessonButtons}
            </div>
        )
    }
}

export default LessonButtonList;