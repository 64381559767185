import { connectFirestoreEmulator, DocumentData } from "firebase/firestore/lite";
import React from "react";
import "../styles/LessonView.css";
import courses from "../data/courses.json";
import LessonStatusCard from "./LessonStatusCard";
import LessonResourcesCard from "./LessonResourcesCard";
import parse from "html-react-parser";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, convertFromRaw, convertToRaw, EditorState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import { Document, Page, pdfjs } from 'react-pdf';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FaPlay } from 'react-icons/fa';
import LessonMobileBar from "./LessonMobileBar";

type Props = {
    adminAccess: boolean;
    activeUserId: string;
    currentLesson: any;
    allLessonData: any;
    courseId: string;
    categoryId: string
    userLessonStatus: any
    setUserLessonStatus: any
    updateLesson: any
    editorEnabled: boolean
    setEditorEnabled: any
    showMainContent: boolean
    showToolBar: boolean
}


const LessonContent = (props: Props) => {

    const [courseName, setCourseName] = React.useState("");
    const [categoryName, setCategoryName] = React.useState("");
    const [lessonUrlInput, setLessonUrlInput] = React.useState("nullString");
    const [videoUrlChange, setVideoUrlChange] = React.useState(false);
    const [descriptionChange, setDescriptionChange] = React.useState(false);
    const [cancelText, setCancelText] = React.useState("Edit Later");
    const [playClicked, setPlayClicked] = React.useState(false);

    React.useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`});

    const [editorState, setEditorState] = React.useState(() =>
        EditorState.createEmpty()
    );

    const cancelEdit = () => {
        props.setEditorEnabled(false);
        if (props.currentLesson.videoUrl !== undefined){
            setLessonUrlInput(props.currentLesson.videoUrl);
        }
        if (props.currentLesson.description !== undefined){
            setEditorState(() => EditorState.createWithContent(convertFromRaw(props.currentLesson.description)));
        }
    }

    const getTextFromEditorBlocks = (allBlocks: any) => {
        let totalText = "";
        for (const block of allBlocks){
            totalText += block.text;
            totalText += "\n";
        }
        return totalText;
    }

    React.useEffect(() => {
        setPlayClicked(false);
        setCancelText("Edit Later");
        if (props.currentLesson !== undefined) {
            if (props.currentLesson.videoUrl !== undefined){
                if (props.currentLesson.videoUrl !== ""){
                    setCancelText("Cancel");
                }
                if (lessonUrlInput !== "nullString" && lessonUrlInput !== props.currentLesson.videoUrl){
                    setVideoUrlChange(true);
                }
                else {
                    setVideoUrlChange(false);
                }
            }
            else {
                if (lessonUrlInput !== "nullString" && lessonUrlInput !== ""){
                    setVideoUrlChange(true);
                }
                else {
                    setVideoUrlChange(false);
                }
            }
            if (props.currentLesson.description !== undefined){
                setCancelText("Cancel");
                if (getTextFromEditorBlocks(props.currentLesson.description.blocks) !== getTextFromEditorBlocks(convertToRaw(editorState.getCurrentContent()).blocks)){
                    setDescriptionChange(true);
                }
                else {
                    setDescriptionChange(false);
                }
            }
            else {
                if (convertToRaw(editorState.getCurrentContent()).blocks !== undefined){
                    if (convertToRaw(editorState.getCurrentContent()).blocks[0].text !== ""){
                        setDescriptionChange(true);
                    }
                    else {
                        setDescriptionChange(false);
                    }
                }
            }
        }

        
    }, [lessonUrlInput, editorState, props.currentLesson])




    React.useEffect(() => {
        props.setEditorEnabled(false);

        if (props.currentLesson !== undefined) {
            if (props.currentLesson.videoUrl !== undefined){
                setLessonUrlInput(props.currentLesson.videoUrl);
            }
            else {
                setLessonUrlInput("");
            }
            if (props.currentLesson.description !== undefined){
                setEditorState(() => EditorState.createWithContent(convertFromRaw(props.currentLesson.description)))
            }
            else {
                setEditorState(() => EditorState.createEmpty())
            }
        }



        if (props.currentLesson !== undefined){
            setCourseName(courses.filter((course:any)=> {return (course.id === props.courseId)})[0]["title"]);
        }
        if (localStorage.getItem("courseTree-" + props.courseId) != null){
            setCategoryName(JSON.parse(localStorage.getItem("courseTree-" + props.courseId)!).filter((x:any) => {return x.chapterId === props.categoryId})[0]["chapterName"]);
        }
    }, [props.currentLesson])


    const lessonVideo = () => {
        if (props.currentLesson.videoUrl){


            if (playClicked){
                return (
                    <iframe id="video-obj" className="lesson-video" src={props.currentLesson.videoUrl + "?autoplay=true"} allow="autoplay; fullscreen;" frameBorder="0"></iframe>
                )

            }
            else {
                return (
                    <div className="lesson-video" onClick={() => setPlayClicked(true)}><div className="play-button"><FaPlay/></div></div>
                )
            }
        }
    }

    // const adminEditButton = () => {
    //     if (props.adminAccess) {
    //         return (
    //             <div>
    //                 <span style={{fontSize:"smaller", cursor: "pointer", color: "lightblue"}} onClick={()=>{props.setEditorEnabled(true)}}>Edit Content</span>
    //             </div>
    //         )
    //     }
    // }


    const updateEditorState = (e: any) => {
        // setChangeDeducted(true);
        setEditorState(e);
    }

    const updateLessonTextContent = (e: any) => {
        e.preventDefault();
        const newLessonObj = JSON.parse(JSON.stringify(props.currentLesson))
        // newLessonObj["description"] = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        newLessonObj["description"] = convertToRaw(editorState.getCurrentContent());
        if (lessonUrlInput !== "nullString" && lessonUrlInput !== props.currentLesson.videoUrl) { 
            newLessonObj["videoUrl"] = lessonUrlInput;
        }
        if (JSON.stringify(newLessonObj) !== JSON.stringify(props.currentLesson)){
            //TODO: Bug in the line above
            props.updateLesson(props.currentLesson.id, newLessonObj)
        }
        else {
            props.setEditorEnabled(false);
        }
    }

    const showLessonTextContent = () => {
        if (props.editorEnabled === true){
            return (

                <div className="main-inner">
                    {/* {lessonVideo()} */}
                    
                    <div className="video-details" style={{ zIndex: 9998 }}>
                        <h2>{props.currentLesson.name}</h2>
                        <span>{courseName} - {categoryName}</span>
                    <br/>
                    <br/>
                    <br/>
                    <form onSubmit={updateLessonTextContent}>
                    <span>Video URL: </span><input type="url" value={lessonUrlInput} onChange={(e) => setLessonUrlInput(e.target.value)} style={{width: "80%", height: "25px", backgroundColor: "#d0d0d0", padding: "5px"}} onKeyPress={(e) => {if (e.key === "Enter") {e.preventDefault()}}}/>

                    <br/>
                    <br/>
                    <br/>

                    <Editor
                        editorState={editorState}
                        onEditorStateChange={(e) => updateEditorState(e)}
                        toolbarStyle={{border: "none", color: "black", backgroundColor: '#d0d0d0'}}
                        editorStyle={{minHeight: "300px", border: "1px solid #353535", backgroundColor: 'rgb(14,14,16)', padding: '10px'}}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker'],
                            inline: { inDropdown: true },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                        }}
                    />
                    <button className={"admin-content-button button-primary"} onClick={cancelEdit}><p>{cancelText}</p></button>
                    {(videoUrlChange || descriptionChange) && <> &nbsp; <button type="submit" className={"admin-content-button bg-green"}><p>Submit</p></button></>}
                    </form>
                    </div>

                </div>
            )
        }
        else {
            return (
                <div className="main-inner">
                    {lessonVideo()}
                    <div className="video-details">
                        <div>
                            <h2>{props.currentLesson.name}</h2>
                            <span>{courseName} - {categoryName}</span>
                        </div>
                        <br/>
                        { parse(draftToHtml(props.currentLesson.description)) }
                    </div>    
                </div>
                
            )
        }
    }



    if (props.currentLesson !== undefined && props.currentLesson.name !== undefined){
        return (
            <div className={(props.showToolBar || props.showMainContent) ? "lesson-content" : ""}>
                {props.showMainContent &&
                    <div className="main-content">
                        {/* <div className="lesson-source">
                            <span><a href="/courses">All Courses</a> / <a href={"/courses/id=" + props.courseId + "/"}>{courseName}</a> / <a>{categoryName}</a></span>
                        </div> */}
                        {showLessonTextContent()}
                    </div>
                    
                }

                {props.showToolBar && 
                
                <div className="side-content">
                    <LessonStatusCard userLessonStatus={props.userLessonStatus} setUserLessonStatus={props.setUserLessonStatus} lessonData={props.allLessonData} categoryId={props.categoryId} lessonId={props.currentLesson.id} userId={props.activeUserId} courseId={props.courseId}/>
                    <LessonResourcesCard adminAccess={props.adminAccess} currentLessonData={props.currentLesson} lessonId={props.currentLesson.id} courseId={props.courseId} updateLesson={props.updateLesson}/>
                </div>
                
                }

            </div>
        )
    }




    else{

        return (
            <div className="lesson-content">
                <p>Loading...</p>
            </div>
        )
    }

}

export default LessonContent;