import React from "react";
import { useState } from "react";
import DefaultSidebar from "../components/DefaultSidebar";
import ProductCardsGrid from "../components/ProductCardsGrid";
import ProductCardv2 from "../components/ProductCardv2";
import Topbar from "../components/Topbar";
import "../styles/HomePage2.css"


type Props = {
    activeUserId: string;
    sidebarOpen: boolean;
    setShowDefaultSidebar: any;
    setOverlappingSidebar: any;
}


const HomePage = (props : Props) => {

    React.useEffect(() => {
        props.setShowDefaultSidebar(true);
        props.setOverlappingSidebar(false);
    }, [])

    return (

            <div className="home-content">
                
                <div className="featured">
                    <div className="featured-content">
                        <h1 className="main-caption">The future of education is here</h1>
                        <p className="secondary-caption">Find success in GCSE Maths with our specialized course. More subjects coming soon!</p>
                    </div>
                </div>
                <div className="content-card medium-space-below">
                    <ProductCardsGrid activeUserId={props.activeUserId} />
                </div>

                <div className="content-card dark-bkg large-space-below">
                    <div className="text-card-inner">
                        <h1>WHAT IS STUDYSPACE?</h1>
                        <p>Studyspace is your e-learning space where you can access videos, resources, and manage your studies for your chosen subject all from one place. Each course provides you with detailed videos with example questions so that you can understand each topic necessary to get yourself the grades you desire. </p>
                        {/* <p>WATCH THE TRAILER:</p> */}

                        <div className="video-container">
                        <iframe className="video-src" src="https://www.youtube.com/embed/viHILXVY_eU" title="YouTube video player" allow="" frameBorder="0"></iframe>
                        </div>
                    </div>
                </div>

                <div className="content-card dark-bkg large-space-below">
                    <div className="text-card-inner">
                        <h1>FOLLOW US</h1>
                        <p> Support us and keep up to date by following our social media!</p>                    
                    </div>
                </div>

                <div className="content-card dark-bkg larger-space-below">
                    <div className="text-card-inner">
                        <h1>ABOUT THE FOUNDER</h1>
                        <p> Saqib Jahangir</p>                    
                    </div>
                </div>

            </div>
    )
}

export default HomePage;