import { FaHome } from "react-icons/fa";
import React from "react";
import * as ReactIcons from 'react-icons/hi'



type Props = {
    buttonText: string,
    iconName: keyof typeof ReactIcons;
    url: string,
}

const SidebarButton = (props: Props) => {

    const IconComponent = ReactIcons[props.iconName];
    
    return (
        <div className={props.url===window.location.pathname ? "sidebar-button active-button" : "sidebar-button"} onClick={()=>{window.location.href=props.url}}>
            <div style={{}}>
                <div className="button-icon"><IconComponent /></div>
                <span className="button-text">{props.buttonText}</span>
            </div>
        </div>
    )
}

export default SidebarButton;