import React from "react"

type Props = {

    style: any,
    show: boolean,
    setContextMenu: any,
    handleEdit: any,
    handleDelete: any,
}

const LessonButtonContextMenu = (props: Props) => {

    React.useEffect(() => {
        console.log(props.style)
    }, [])

    if (props.show){
        return (
            <div className="context-menu" style={props.style}>

            {props.handleEdit && <div className="context-menu-button" onClick={props.handleEdit}>
                <p style={{display:"inline-block", fontSize:"12px"}}>Edit</p>
            </div>}

            <div className="context-menu-button" onClick={props.handleDelete}>
                <p style={{display:"inline-block", fontSize:"12px"}}>Delete</p>
            </div>


            {/* &nbsp; */}
            {/* <i className="fa fa-trash-o" style={{display:"inline-block", fontSize:"24px", color:"red", margin:"0 auto"}}></i> */}
            </div>
        )
    }
    else {
        return (<></>);
    }
}

export default LessonButtonContextMenu;