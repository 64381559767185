import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DraggableLessonButton from "./DraggableLessonButton";
import LessonButtonContextMenu from "./LessonButtonContextMenu";


type Props = {
    lessonData: any;
    setLessonData: any;
    searchFilter: any;
    getLessonStatusStyle: any;
    getSelectedLessonStyle: any;
    setSelectedLesson: any;
    setEditorEnabled: any;
    lastLessonRef: any;
    handleRightClick:any;
    rightClickedId: string;
    goToLessonViewMobile: any;
}

const DraggableLessonButtonList = (props: Props) => {

    let lessonButtons: {} | null | undefined;

    if (props.lessonData !== undefined){

        lessonButtons = props.lessonData.map((lesson:any, index: number) => {
            if(props.searchFilter(lesson)){
                return (
                    <Draggable key={lesson.id} draggableId={lesson.id} index={index}>
                        {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <DraggableLessonButton
                                lessonStatusStyle={props.getLessonStatusStyle(lesson.id)!}
                                selectedLessonStyle={props.getSelectedLessonStyle(lesson.id)}
                                setSelectedLesson={props.setSelectedLesson}
                                setEditorEnabled={props.setEditorEnabled}
                                lesson={lesson}
                                index={index}
                                lastLessonRef={(index+1 == props.lessonData.length) ? props.lastLessonRef : 0}
                                handleRightClick={props.handleRightClick}
                                isRightClicked={props.rightClickedId === lesson.id}
                                goToLessonViewMobile={props.goToLessonViewMobile}
                            />
                        </div>
                        )}
                    </Draggable>
                )
            }
        })
    }


    const onDragEnd = (result: any) => {
        if (!result.destination) {
          return;
        }
    
        const items = Array.from(props.lessonData);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        props.setLessonData(items);
    };




    return (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="lesson-buttons">
              {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {lessonButtons}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

            </DragDropContext>
      );
}

export default DraggableLessonButtonList;